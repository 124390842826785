import React, { useContext, useEffect, useRef } from "react";
import InputQuestion from "../inputQuestion/inputQuestion";
import { MdChevronRight } from "react-icons/md";
import "./_cardQuestion.scss";
import { ManagerProgressContext } from "../../contexts/managerProgres";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

function CardQuestion({ title, options, postDataQuestion, subTemaId, idForm, answer, lastQuestion, finishTema}){
  let historyInputChosen = answer
  const containerQuestionRef = useRef(null);
  const clickShowRef = useRef(null);
  const { managerBtn, dataTemaAswered } = useContext(ManagerProgressContext)

  console.log(1)
  useEffect(() => {
    const inputSelector = document.querySelectorAll(
      `input[name='${subTemaId}']`
    );

    if(historyInputChosen === null || historyInputChosen === undefined) return


    inputSelector.forEach((el, index) => {
      const dataOrder = el.getAttribute('data-order');
      const element = document.getElementById(historyInputChosen);
      const dataOrderAnswer = element.getAttribute('data-order');

      if(dataOrder < dataOrderAnswer){
        el.classList.add("input-onClick")
      }

      if(Number(el.value) === historyInputChosen){
        el.setAttribute('checked', '')
      }

      if(managerBtn === false){
        el.disabled = true
      }
    })

    let statusQuestionAswered = historyInputChosen && true

    if(statusQuestionAswered){
      clickShowRef.current.style.borderLeft = '4px solid var(--color-tema)';
    }else{
      clickShowRef.current.style.borderLeft = 'none'
    }

  },[historyInputChosen, subTemaId])

  function showContainer(){
    const boxShowQuestion = document.querySelectorAll(".box-show-question")
    const containerCardquestion = document.querySelectorAll(".container-card-questionario")
    const form = document.querySelectorAll('form')
    const formArray = [...form]
    const inputChosen = document.querySelector(
      `input[name='${subTemaId}']:checked`
    )

    for(let form in formArray){
      if(formArray[form].id === `form-${subTemaId-1}` && window.getComputedStyle(formArray[form]).display === 'none'){
        // formArray[form].style.display = 'block'
        formArray[form].classList.add("show-form")
        containerCardquestion[form].classList.add("show")
        boxShowQuestion[form].classList.add("box-show-question2");
      }else{
        formArray[form].style.display = 'none'
        formArray[form].classList.remove("show-form")
        containerCardquestion[form].classList.remove("show")
        boxShowQuestion[form].classList.remove("box-show-question2");
      }
    }

    let statusQuestionAswered = inputChosen && true

    if(statusQuestionAswered){
      clickShowRef.current.style.borderLeft = '4px solid var(--color-tema)';
    }else{
      clickShowRef.current.style.borderLeft = 'none'
    }
  }

  function registerAnswerQuestion() {
    const form = document.querySelectorAll('form')
    const boxShowQuestion = document.querySelectorAll(".box-show-question")
    const containerCardquestion = document.querySelectorAll(".container-card-questionario")
    const inputChosen = document.querySelector(
      `input[name='${subTemaId}']:checked`
    )

    postDataQuestion(subTemaId, inputChosen.value);

    for(let elForm in form){
      if(form[elForm].id === `form-${subTemaId-1}`){
        form[elForm].style.display = 'none'
        containerCardquestion[elForm].classList.remove("show")
        form[elForm].classList.remove("show-form")

        boxShowQuestion[elForm].classList.remove("box-show-question2")
      }
    }

    console.log(1)

    let statusQuestionAswered = inputChosen && true

    if(statusQuestionAswered){
      clickShowRef.current.style.borderLeft = '4px solid var(--color-tema)';
    }else{
      clickShowRef.current.style.borderLeft = 'none'
    }
  }

  function activeSelection(e) {
    if(managerBtn !== true) return

    const inputSelector = document.querySelectorAll(
      `input[name='${subTemaId}']`
    );
    const inputChecked = e.target.getAttribute('data-order')

    if(inputChecked === 0) return

    inputSelector.forEach(el => {

      if(Number(el.getAttribute('data-order')) === 0) return

      if(el.getAttribute('data-order') < inputChecked){
        el.classList.add("input-onClick")
      }else{
        el.classList.remove("input-onClick");
      }
    })
  }

  function submitQuestion(){
    const btnSubmitQuestion = document.querySelector(".btn-send-request")?.offsetTop
    const inputChosen = document.querySelector(
        `input[name='${subTemaId}']:checked`
    )

    if(lastQuestion && finishTema && !answer){
        window.scroll(0, btnSubmitQuestion)
        localStorage.setItem('@tetse', JSON.stringify([subTemaId, inputChosen.value]))

    }else{
        registerAnswerQuestion()
    }
    handleClose()
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div ref={containerQuestionRef} className={`container-card-questionario ${subTemaId}`} >

      <div
        ref={clickShowRef}
        onClick={showContainer}
        className="box-show-question"
      >
        <p>{title}</p>
        <MdChevronRight className="icon-click" />
      </div>
      <form id={`form-${idForm-1}`} >
        {options.map((el) => (
          <InputQuestion
            activeSelection={activeSelection}
            key={el.id}
            label={el.title}
            id={el.id}  
            subTemaId={subTemaId}
            order={el.order}
          />
        ))}

        {
          !dataTemaAswered && (
          <button type="button" onClick={handleClickOpen} className="btn-question">
          Salvar
          </button>)
        }

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Respondendo questionário"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao confirmar você responderá a seguinte questão, deseja enviar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Não</Button>
            <Button onClick={submitQuestion} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
export default CardQuestion;
